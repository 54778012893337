/* .react-trello-lane header {
  min-width: auto;
} */

/* .react-trello-lane div:nth-child(1) {
  min-width: 80px;
} */

.react-trello-board {
    height: 100% !important;
}

.react-trello-lane>div:nth-child(2) {
    min-width: 150px;
}

.smooth-dnd-container.horizontal>.smooth-dnd-draggable-wrapper {
    height: calc(100vh - 200px);
}

.react-trello-lane>header>span:nth-child(1) {
    width: 80%;
}

.react-trello-lane>header>span:nth-child(2) {
    width: 20%;
}