:root {
    --fc-button-text-color: #363636;
    --fc-button-bg-color: #fff;
    --fc-button-border-color: #fff;
    --fc-button-active-bg-color: #fff;
    --fc-button-active-border-color: #00913A;
    --fc-button-hover-bg-color: #fff;
    --fc-button-hover-border-color: #00913A;
}

.fc-list-event {
    cursor: pointer;
}

.fc-h-event {
    border: none;
}