body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* react leaflet */
.leaflet-container {
  height: 100%;
  width: 100%;
  position: relative;
}

.leaflet-control-attribution {
  display: none;
}

.leaflet-div-icon {
  background: transparent;
  border: none;
}

.leaflet-marker-icon .image{
  position: relative;
  top: -32px;
}

.leaflet-marker-icon .shadow{
  position: relative;
  top: -32px;
}

.leaflet-marker-icon .number{
  position: relative;
  top: -44px;
  left: 0.5px;
  font-size: 14px;
  line-height: 21px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  border: 1px solid #4485B1; 
  text-align: center;
  color: #000;
  background-color: #fff;
}

/* print */
@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: always;
  }
}

@page {
  size: auto;
  margin: 20mm;
}

/* Winbox */
.winbox-container {
  z-index: 1500!important;
}

.DayPickerInput-Overlay {
  z-index: 200; /* 避免被MUIDataTable的header(z-index 100)遮住 */
  position: absolute;
}